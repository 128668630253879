<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Registro
              </v-btn>
              <v-btn
                dark
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="networks"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          @item-expanded="getItems"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="overline"
              v-if="!loadingAction"
            >
              <div v-if="item.id === cellId" class="ml-6">
                <li v-for="(cell, ic) in cells" :key="ic">
                  {{ cell.name }}
                </li>
              </div>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        persistent
        max-width="1200px"
        :fullscreen="fullScreen"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
            style="padding-right: 10px !important"
          >
            <v-toolbar-title class="overline">Rede</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="fullScreen = !fullScreen">{{
                setIconScreen()
              }}</v-icon>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="name"
                    label="Nome"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    outlined
                    :items="days"
                    v-model="meetingDay"
                    label="Dia Reunião"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    type="time"
                    v-model="meetingTime"
                    label="Horário"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    outlined
                    :items="leaders"
                    item-value="id"
                    item-text="person.corporateName"
                    v-model="leaderId"
                    label="Supervisor"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    v-mask="'#####-###'"
                    label="CEP"
                    v-model="zipCode"
                    required
                    @change="searchAddress()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    outlined
                    label="Logradouro"
                    v-model="street"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    outlined
                    label="Número"
                    v-model="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    label="Complemento"
                    v-model="complement"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    label="Bairro"
                    v-model="zone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    label="Cidade"
                    v-model="city"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    outlined
                    label="UF"
                    v-model="state"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Referencia Endereço"
                    v-model="referenceAddress"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="registerOrUpdate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
import SearchAddressService from "@/services/address.service";

export default Vue.extend({
  name: "Redes",
  components: {
    InternalMenu,
    PageHeader,
  },
  data: () => ({
    title: "Redes",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Aniversariantes",
            to: "/customers/birthdays",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Membros",
            to: "/members",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Grupos/Células",
            to: "/promise/cells",
            permission: "promise.cells.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Redes",
        disabled: true,
        href: "/members",
      },
    ],
    deleteDialog: false,
    cells: [],
    leaders: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    meetingDay: "",
    leaderId: null,
    networkId: null,
    meetingTime: "",
    name: "",
    street: "",
    zone: "",
    complement: "",
    referenceAddress: "",
    number: "",
    city: "",
    state: "",
    zipCode: "",
    openDialog: false,
    fullScreen: false,
    cellId: null,
    days: [
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
      "Domingo",
    ],
    networks: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Supervisor",
        align: "start",
        value: "leader.person.corporateName",
        filtering: true,
      },
      {
        text: "Reunião",
        align: "start",
        value: "meetingDay",
        filtering: true,
      },
      {
        text: "Bairro",
        align: "start",
        value: "zone",
        filtering: true,
      },
      {
        text: "Ações",
        value: "actions",
      },
    ],
    expanded: [],
    singleExpand: false,
  }),
  methods: {
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    async searchAddress() {
      const zipCode = this.zipCode.replace(/([^\d])+/gim, "");
      const res = await SearchAddressService(zipCode);
      if (res.success) {
        res.address.zipCode = this.zipCode;
        this.street = res.address.street;
        this.zone = res.address.zone;
        this.city = res.address.city;
        this.state = res.address.state;
      } else {
        this.snackbar.text = "Houve erro ao buscar o CEP";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    deleteItem() {
      http.delete(`cells/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getNeworks() {
      this.loading = true;
      http.get("promise/cells?filter=type||eq||network").then(
        (data) => {
          this.networks = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
    getLeaders() {
      this.loading = true;
      http.get("gerentor/customers").then((data) => {
        this.leaders = data.data;
        this.loading = false;
      });
    },
    getItems({ item }) {
      this.loadingAction = true;
      this.cellId = item.id;
      this.cells = [];
      http.get(`promise/cells?filter=networkId||eq||${item.id}`).then(
        (data) => {
          this.cells = data.data;
          this.loadingAction = false;
        },
        () => (this.loadingAction = false)
      );
    },
    registerOrUpdate() {
      this.loadingAction = true;
      const payload = {
        name: this.name,
        meetingDay: this.meetingDay,
        street: this.street,
        number: this.number,
        zone: this.zone,
        city: this.city,
        state: this.state,
        zipCode: this.zipCode,
        complement: this.complement,
        referenceAddress: this.referenceAddress,
        meetingTime: this.meetingTime,
        leaderId: this.leaderId,
        type: "network",
        congregationId: localStorage.getItem("congregationId"),
      };
      if (this.id) {
        http.put(`promise/cells/${this.id}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("promise/cells", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.name = "";
      this.id = "";
      this.meetingDay = "";
      this.leaderId = null;
      this.complement = "";
      this.number = "";
      this.street = "";
      this.state = "";
      this.zipCode = "";
      this.city = "";
      this.zone = "";
      this.meetingTime = "";
      this.referenceAddress = "";
      this.getNeworks();
    },
    editItem(item) {
      this.name = item.name;
      this.meetingDay = item.meetingDay;
      this.id = item.id;
      this.meetingDay = item.meetingDay;
      this.zone = item.zone;
      this.state = item.state;
      this.city = item.city;
      this.street = item.street;
      this.zipCode = item.zipCode;
      this.complement = item.complement;
      this.number = item.number;
      this.meetingTime = item.meetingTime;
      this.leaderId = item.leaderId;
      this.referenceAddress = item.referenceAddress;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getLeaders();
    this.getNeworks();
  },
});
</script>